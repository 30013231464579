<template>
	<div class="page">
		<!-- 顶部菜单 -->
		<topBar :curIndex="2"></topBar>
		<div class="block30"></div>
		<div class="huaxue_info_detail relative w1239" v-if="dataobj">
			<div class="titlediv">
				<div class="title fontsize24">{{dataobj.title}}</div>
				<div class="timer fontsize14" v-if="moduleId!=4">时间：{{dataobj.createDate}}<span v-if="dataobj.userName" style="margin-left: 10px;">{{dataobj.userName}}</span></div>
				<div class="videodiv" v-if="dataobj.articleVideo">
					<video :src="dataobj.articleVideo" class="video" controls="controls">您的浏览器不支持视频播放</video>
				</div>
			</div>
			<div class="data_con">
				<div class="fontsize14" v-html="dataobj.articleContent"></div>
			</div>
		</div>
		<div class="block60"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
import  topBar  from "../components/topBar/topBar.vue";
export default {
	components: {
		topBar
	},
	data() {
		return {
			type:"aboutUs",
			moduleId:3,//1:系统公告,2:资讯文章,3:关于我们 4是关于我们
			uid:0,//详情
			dataobj:{
				title:"关于我们",
				createDate:'',
				userName:"",
				articleContent:"暂无相关内容~"
			},
		};
	},
	mounted: function() {
		if(this.$route.query&&this.$route.query.type){
			this.type = this.$route.query.type
			if(this.type=="user"){
				this.moduleId = 4
			}
		}
		this.getDataDetail()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//获取详情
		getDataDetail() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 1,
				moduleId: this.moduleId,//1:系统公告,2:资讯文章,3:关于我们 4是关于我们
			};
			this.$http.post('articleModule/article-info/getSecondPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.dataobj = records[0]
					}
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
