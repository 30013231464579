<template>
	<!-- 菜单栏目 -->
	<div class="xc_caidan_box relative">
		<div class="w1240 relative">
			<div class="logo_search">
				<div class="logo">
					<img class="img" src="../../assets/static/logoimg.png"/>
				</div>
				<div class="search_box">
					<input class="input" v-model="skeyword" placeholder="请输入商品名/品牌名/厂家名称"/>
					<div class="btn1" @click="handleSearch">搜索</div>
				</div>
				<div class="showcar_box relative" @click="gotoCar">
					<img class="img" src="../../assets/static/Frame8.png"/>
					<div class="text">采购车</div>
					<div class="number" v-if="cartCount>0">{{cartCount}}</div>
				</div>
			</div>
			<div class="menulist_box">
				<div class="menuobj">全部药品分类</div>
				<div class="itemobj" @click="menuClick(index, item.url)" :class="{'hover':curpage==item.url}" v-for="(item,index) in menulist" :key="index">
					{{item.menu}}
				</div>
				<!-- <div class="itemobj" :class="{'hover':curIndex==0}">首页</div>
				<div class="itemobj" :class="{'hover':curIndex==1}">今日特价</div>
				<div class="itemobj" :class="{'hover':curIndex==2}">拼团</div>
				<div class="itemobj" :class="{'hover':curIndex==3}">积分购</div>
				<div class="itemobj" :class="{'hover':curIndex==4}">资讯中心</div>
				<div class="itemobj" :class="{'hover':curIndex==5}">优惠中心</div>
				<div class="itemobj" :class="{'hover':curIndex==6}">关于我们</div> -->
			</div>
			<!-- 扫码 -->
			<div class="qrcode_box">
				<img class="img" src="../../assets/static/qrcode_web.png"/>
				<!-- <div class="text">扫码进入</div> -->
				<div class="text">小程序采购</div>
			</div>
		</div>
	</div>
</template>
<script>
import {mapState} from 'vuex'
export default {
	name: 'topBar',
	props: {
		curIndex: {
			type: Number,
			default: () => {
				return 0;
			}
		},
		keyword: {
			type: String,
			default: () => {
				return "";
			}
		},
	},
	data() {
		return {
			skeyword:"",//搜索关键词
			menulist: [
				{
					index: 0,
					menu: '首页',
					url: 'home'
				},
				{
					index: 1,
					menu: '全部商品',
					url: 'category'
				},
				{
					index: 2,
					menu: '今日特价',
					url: 'todayPrice'
				},
				{
					index: 3,
					menu: '拼团',
					url: 'teamPage'
				},
				{
					index: 4,
					menu: '积分购',
					url: 'exchange'
				},
				// {
				// 	index: 5,
				// 	menu: '资讯中心',
				// 	url: 'information'
				// },
				{
					index: 5,
					menu: '优惠中心',
					url: 'couponCenter'
				},
				{
					index: 6,
					menu: '关于我们',
					url: 'aboutUs'
				}
			],
		};
	},
	computed: {
		cartCount(){
			return this.cartLength || 0;
		},
		...mapState(['userInfo', 'isLogin','curpage']),
	},
	watch:{
		keyword(data){
			this.skeyword = data
		},
	},
	created() {
		this.skeyword = this.keyword
		//window.addEventListener("scroll", this.handleScroll, true);
	},
	methods: {
		menuClick(index, url) {
			if(url=="coupon"){
				//优惠券
				if(this.curpage!="mine"){
					this.$router.push({ path: '/mine', query: {redirect: "coupon"}})
					this.$store.commit('SET_CURPAGE', 'mine')
				}else{
					this.$router.push({ path: '/BlankPage', query: {redirect: "coupon",pathto:"mine"}})
				}
			}else if(url){
				// console.log('我点击了menu');
				//this.$emit("menuClick",url)
				this.$store.commit('SET_CURPAGE', url)
				this.$router.push({
					path: "/"+url
				});
			}else{
				this.$message.info("此模块暂未开放")
			}
		},
		handleSearch(){
			this.$router.push({ path: '/BlankPage', query: {keyword:this.skeyword,type: "search",pathto:"category"}})
		},
		gotoCar(){
			this.$router.push({
				path: "/showCar"
			});
		},
	}
};
</script>
<style></style>
